<template>
  <div class="login-page">
    <div class="login-box">
      <div class="login-title">用户登录</div>
      <div class="row">
        <el-input v-model="username"
                  placeholder="请输入内容">
          <template slot="prepend">账户：</template>
        </el-input>
      </div>
      <div class="row">
        <el-input v-model="pwd"
                  type="password"
                  placeholder="请输入内容">
          <template slot="prepend">密码：</template>
        </el-input>
      </div>
      <div class="row">
        <el-button type="primary"
                   style="width:100%;"
                   @click="onLogin">登陆</el-button>
      </div>
    </div>
  </div>
</template>
<style lang='less' scoped>
.login-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  height: 100%;
  .login-box {
    width: 360px;
    margin: 0px auto;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(19, 51, 107, 0.08) 0px 0px 1px 0px,
      rgba(19, 51, 107, 0.05) 0px 4px 4px 0px;
    .login-title {
      margin-bottom: 20px;
      font-size: 20px;
      text-align: center;
      color: #333;
      font-weight: bold;
    }
    .row {
      margin-bottom: 20px;
    }
  }
}
</style>

<script>
export default {
  data () {
    return {
      loading: false,
      username: '',
      pwd: ''
    }
  },
  components: {

  },
  computed: {

  },
  mounted () {

  },
  methods: {
    onLogin () {
      const data = {
        username: this.username,
        pwd: this.pwd
      }
      this.$httpHelper.post('login', data).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.$store.dispatch('onSetLoginUser', res.data)
          this.$router.replace({ path: '/messagelist' })
        } else {
          this.$message.error(res.msg)
        }
      }).catch(function(ex) {
        this.loading = false
        this.$message.error('登录失败,请重新登录')
      })
    }
  }
}
</script>
