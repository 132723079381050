<!--  -->
<template>
  <div class="header">
    {{title}}
  </div>
</template>
<style lang='less' scoped>
.header {
  padding: 20px;
  border-bottom: 1px solid #f1f1f1;
  color: #555;
}
</style>
<script>
export default {
  props: {
    title: String
  },
  data () {
    return {

    }
  },
  components: {

  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}
</script>
