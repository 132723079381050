<!--  -->
<template>
  <div>
    <PubHeader title="文件管理"></PubHeader>
    <div>
      <div class="tools-box">
        <el-button type="primary"
                   size="small"
                   @click="onUpload">上传文件</el-button>
      </div>
      <el-table border
                :data="tableData"
                :height="innerHeight"
                v-loading="loading"
                style="width: 100%">
        <el-table-column prop="filename"
                         label="文件名"
                         width="300">
        </el-table-column>
        <el-table-column prop="rowcount"
                         label="数据行数"
                         width="100">
        </el-table-column>
        <el-table-column prop="state"
                         width="100"
                         label="状态">
          <template slot-scope="scope">
            <div v-if="scope.row.state===0"
                 style="color:red">禁用</div>
            <div style="color:green"
                 v-else>开启</div>
          </template>
        </el-table-column>
        <el-table-column prop="addtime"
                         label="上传时间">
        </el-table-column>
        <el-table-column fixed="right"
                         label="操作">
          <template slot-scope="scope">
            <el-button v-if="scope.row.state===0"
                       @click="updateState(scope.row)"
                       size="mini">开启</el-button>
            <el-button v-else
                       @click="updateState(scope.row)"
                       size="mini">禁用</el-button>
            <el-button size="mini"
                       type="danger"
                       v-loading="isDelete"
                       @click="deleteFile(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="上传文件"
               width="400px"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :visible.sync="dialogUploadfile">
      <UploadFiles @complete="onComplete"></UploadFiles>
    </el-dialog>
  </div>
</template>
<style lang='less' scoped>
.tools-box {
  padding: 10px;
}
</style>
<script>
import PubHeader from '../components/PubHeader.vue'
import UploadFiles from '../components/UploadFiles.vue'
export default {
  data () {
    return {
      isDelete: false,
      loading: false,
      tableData: [],
      dialogUploadfile: false
    }
  },
  components: {
    PubHeader,
    UploadFiles
  },
  computed: {
    innerHeight () {
      const screenHeight = window.innerHeight - 156;
      console.log(screenHeight); // 输出当前屏幕的高度
      return screenHeight
    }
  },
  mounted () {
    this.getFiles()
  },
  methods: {
    onUpload () {
      this.dialogUploadfile = true
    },
    onComplete () {
      this.dialogUploadfile = false
      this.getFiles()
    },
    getFiles () {
      this.loading = true
      this.$httpHelper.post('v1/getfiles', {}).then(res => {
        this.loading = false
        if (res.code === 0) {
          // for (let i = 0; i < 10; i++) {
          //   res.data.forEach(element => {
          //     this.tableData.push(element)
          //   });
          // }

          this.tableData = res.data
        }
      })
    },
    updateState (row) {
      const data = { state: !row.state, id: row.fileid }
      this.$httpHelper.post('v1/updatefilestate', data).then(res => {
        if (res.code === 0) {
          this.getFiles()
        }
      })
    },
    deleteFile (row) {
      this.isDelete = true
      const data = { id: row.fileid }
      this.$httpHelper.post('v1/deletefile', data).then(res => {
        this.isDelete = false
        if (res.code === 0) {
          this.getFiles()
        }
      })
    }
  }
}
</script>
