import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import httpHelper from './common/http.js'
import helper from './common/helper.js'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

Vue.prototype.$httpHelper = httpHelper
Vue.prototype.$helper = helper
Vue.prototype.$store = store
Vue.config.productionTip = false

Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
