<!--  -->
<template>
  <div v-if="botInfo">
    <div v-if="botInfo.qrcode"
         class="qr-code">
      <div class="code-img"
           v-html="botInfo.qrcode"></div>
      <div>请使用微信扫码登录</div>
    </div>
    <div class="error"
         v-else-if="botInfo.state==='logging'">
      正在启动机器人
    </div>
    <div class="error"
         v-else-if="botInfo.state==='logout'">
      机器人已停止运行
    </div>
    <div class="error"
         v-else-if="botInfo.state==='error'">
      机器人运行异常，请刷新页面!
      <el-button type="primary"
                 style="width:100%;"
                 @click="onReload">刷新</el-button>
    </div>
    <div class="login-info"
         v-else-if="botInfo.user">
      <el-avatar shape="square"
                 :size="100"
                 :src="botInfo.user.avatar"></el-avatar>
      <div>{{botInfo.user.name}}</div>
      <div class="tip">机器人运行中</div>
    </div>
  </div>
  <div class="login-info"
       v-else>
    机器人状态检查，请稍后...
  </div>
</template>
<style lang='less' scoped>
.qr-code {
  white-space: pre-wrap;
  font-size: 20px;
  line-height: 20.5px;
  text-align: center;
  color: #06d769;
  .code-img {
    width: 250px;
    margin: 0px auto;
  }
}
.btn-reload-qr {
  margin-top: 20px;
}
.login-info {
  width: 200px;
  background-color: #f1f1f1;
  padding: 100px;
  border-radius: 20px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  margin-top: 20px;
  div {
    margin: 10px 0px;
  }
  .tip {
    color: red;
  }
}
.error {
  text-align: center;
  line-height: 100px;
  color: red;
}
</style>
<script>
export default {
  props: {
    botId: String
  },
  data () {
    return {
      timer: null,
      botInfo: null
    }
  },
  components: {

  },
  computed: {

  },
  mounted () {
    this.onInit()
  },
  methods: {
    onReload () {
      this.onInit()
    },
    onInit () {
      const _this = this
      this.startBot()
      if (!this.timer) {
        clearInterval(this.timer)
      }
      this.timer = setInterval(function() {
        _this.getBotInfo()
      }, 10000)
    },
    startBot () {
      this.$httpHelper.post('v1/startbot', {}).then(res => {
        // if (res.code === 0) {
        //   if (res.data.isLogin) {
        //     this.loginUser = res.data.loginUser
        //   } else {
        //     this.qrinfo = res.data.qrcode
        //     this.loginUser = null
        //   }
        // } else {
        //   this.loginUser = null
        // }
      })
    },
    getBotInfo () {
      if (this.botId) {
        this.$httpHelper.post('v1/getbotinfo', { botId: this.botId }).then(res => {
          if (res.code === 0) {
            this.botInfo = res.data
          }
        })
      }
    }
  },
  destroyed () {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}
</script>
