<!--  -->
<template>
  <div>
    <el-upload v-loading="loading"
               element-loading-text="正在处理数据,请等待..."
               ref="upload"
               class="upload-demo"
               drag
               name="files"
               :disabled="disabled"
               :action="uploadUrl"
               :headers="uploadHeaders"
               :on-success="onSuccess"
               :on-progress="onProgress"
               :limit="4"
               accept=".csv,.xls,.xlsx"
               multiple>
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
      <div class="el-upload__tip"
           slot="tip">只能上传excel,cvs文件,一次最多上传4个文件</div>
    </el-upload>
  </div>
</template>
<style lang='less' scoped>
</style>
<script>
export default {
  data () {
    return {
      loading: false,
      disabled: false,
      uploadUrl: this.$httpHelper.dataApiUrl + 'v1/uploadfiles',
      uploadCount: 0
    }
  },
  components: {

  },
  computed: {
    uploadHeaders () {
      return {
        authorization: this.$httpHelper.getToken()
      }
    }
  },
  mounted () {

  },
  methods: {
    onProgress (event, file, fileList) {
      this.disabled = true
      this.loading = true
    },
    onSuccess (response, file, fileList) {
      this.uploadCount++
      if (this.uploadCount >= fileList.length) {
        this.loading = false
        this.disabled = false
        this.$refs.upload.clearFiles()
        this.$emit('complete')
      }
    }
  }
}
</script>
