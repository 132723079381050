import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import MessageList from '../views/MessageList.vue'
import FileList from '../views/FileList.vue'
import WechatBot from '../views/WechatBot.vue'
import Login from '../views/Login.vue'

Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'MessageList',
    component: MessageList,
    meta: {
      isLogin: false,
      title: 'Ai助手',
      keepAlive: false
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      isLogin: false,
      title: 'Ai助手',
      keepAlive: false
    }
  },
  {
    path: '/messagelist',
    name: 'MessageList',
    component: MessageList,
    meta: {
      isLogin: true,
      title: 'Ai助手',
      keepAlive: false
    }
  },
  {
    path: '/filelist',
    name: 'FileList',
    component: FileList,
    meta: {
      isLogin: true,
      title: 'Ai助手',
      keepAlive: false
    }
  },
  {
    path: '/Bot',
    name: 'WechatBot',
    component: WechatBot,
    meta: {
      isLogin: true,
      title: 'Ai助手',
      keepAlive: false
    }
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loginUser = localStorage.getItem('loginuser')
  if (loginUser != null && loginUser !== 'null') {
    store.dispatch('onSetLoginUser', JSON.parse(loginUser))
    next()
  } else {
    if (to.meta.isLogin === true) {
      next({
        path: '/login'
      })
    } else {
      next()
    }
  }
})

export default router
