import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginUser: null
  },
  getters: {
    loginUser: state => state.loginUser
  },
  mutations: {
    setLoginUser (state, value) {
      if (value) {
        state.loginUser = value
        localStorage.setItem('loginuser', JSON.stringify(value))
      } else {
        state.loginUser = null
        localStorage.clear()
      }
    }
  },
  actions: {
    onSetLoginUser ({ commit }, val) {
      commit('setLoginUser', val)
    }
  },
  modules: {
  }
})
