<template>
  <div class="app">
    <div class="nav"
         v-if="isLogin">
      <div class="top">
        <div :class="menuClassName('MessageList')">
          <router-link to="/MessageList">
            <div class="icon">
              <i class="el-icon-chat-dot-round"></i>
            </div>
            <div class="text">消息</div>
          </router-link>
        </div>
        <div :class="menuClassName('FileList')">
          <router-link to="/FileList">
            <div class="icon">
              <i class="el-icon-coin"></i>
            </div>
            <div class="text">文件</div>
          </router-link>
        </div>
        <div :class="menuClassName('WechatBot')">
          <router-link to="/Bot">
            <div class="icon">
              <i class="el-icon-cpu"></i>
            </div>
            <div class="text">微信</div>
          </router-link>
        </div>
      </div>
      <div class="bottom">
        <el-popover placement="right"
                    trigger="click">
          <div class="user-info">
            <div class="header">{{userName}}</div>
            <div class="btn-exit">
              <el-button size="small"
                         type="danger"
                         @click="onExit">退出</el-button>
            </div>
          </div>
          <div class="header"
               slot="reference">{{userName}}</div>
        </el-popover>
      </div>
    </div>
    <div class="page">
      <router-view />
    </div>
  </div>
</template>
<style lang="less">
html,
body {
  background-color: #f4f4f7;
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 100%;
  display: block;
  box-sizing: border-box;
}
.user-info {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding: 30px 0px;
  .header {
    background-color: #409eff;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 13px;
    font-weight: bold;
    cursor: pointer;
  }
  .btn-exit {
    margin-top: 30px;
  }
}
.app {
  background-color: #f4f4f7;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: 20px;
  padding-left: 0px;
  width: 100%;
  height: 100%;
  .nav {
    width: 64px;
    flex: none;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 10px 0px 10px 10px;
    a {
      color: #667085;
      text-decoration: none;
    }
    .top {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .bottom {
      bottom: 0px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
    .header {
      background-color: #409eff;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      text-align: center;
      line-height: 40px;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;
    }
    .menu {
      display: flex;
      flex-direction: column;
      color: #667085;
      padding: 10px;
      border-radius: 10px;
      margin-bottom: 10px;
      .icon {
        font-size: 24px;
        font-weight: bold;
      }
      .text {
        font-size: 12px;
        margin-top: 5px;
        line-height: 1;
      }
      &:hover {
        background-color: #fff;
        box-shadow: rgba(19, 51, 107, 0.08) 0px 0px 1px 0px,
          rgba(19, 51, 107, 0.05) 0px 4px 4px 0px;
        .icon,
        .text {
          color: #409eff !important;
        }
      }
    }
    .selected {
      background-color: #fff;
      box-shadow: rgba(19, 51, 107, 0.08) 0px 0px 1px 0px,
        rgba(19, 51, 107, 0.05) 0px 4px 4px 0px;
      .icon,
      .text {
        color: #409eff !important;
      }
    }
  }
  .page {
    border: 1px solid #ccc;
    border-width: 1px;
    border-radius: 16px;
    background-color: #fbfbfc;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
    flex: 1;
    height: calc(100vh - 40px);
    margin-left: 15px;
    // div {
    //   position: relative;
    //   width: 100%;
    //   height: calc(100vh - 40px);
    // }
  }
}
</style>
<script>
export default {
  data () {
    return {

    }
  },
  components: {

  },
  computed: {
    menuClassName () {
      return function(name) {
        if (name === this.$route.name) {
          return 'menu selected'
        } else {
          return 'menu'
        }
      }
    },
    userName () {
      return this.$store.getters.loginUser.nickname
    },
    isLogin () {
      const user = this.$store.getters.loginUser
      console.log(user)
      if (user && user.token) {
        return true
      }
      return false
    }
  },
  mounted () {
  },
  methods: {
    onExit () {
      this.$store.dispatch('onSetLoginUser', null)
      this.$router.replace({ path: '/login' })
    }
  }
}
</script>
