import 'whatwg-fetch'
import router from '../router'
import store from '../store'
import { Message } from 'element-ui';
export default {
  dataApiUrl: 'https://botapi.shuda.co/',
  // dataApiUrl: 'https://rqneotchmagw.gzg.sealos.run/',
  // dataApiUrl: 'https://xyipdryjshgs.cloud.sealos.io/',
  // dataApiUrl: 'http://localhost:3001/',
  getToken () {
    const loginUser = localStorage.getItem('loginuser')
    try {
      if (loginUser) {
        const jsonUser = JSON.parse(loginUser)
        return jsonUser.token
      }
    } catch (e) {
      return ''
    }
    return ''
  },
  createRequest (data, method) {
    if (method == null || method === '') {
      method = 'POST'
    }
    data = JSON.stringify(data)
    const token = this.getToken()
    const options = {
      method: method,
      body: data,
      headers: {
        'Content-Type': 'application/json',
        authorization: token
      },
      responseType: 'json'
    }
    return options
  },
  /**
  * 发起post请求
  */
  post (funApi, data) {
    // let targetUrl = 'http://ai.geezhou.com/' + funApi
    let targetUrl = ''
    if (funApi.indexOf('http') < 0) {
      targetUrl = this.dataApiUrl + funApi
    } else {
      targetUrl = funApi
    }
    const options = this.createRequest(data, 'post')
    return new Promise((resolve, reject) => {
      fetch(targetUrl, options).then(function(res) {
        const result = res.json()
        return result
      }).then(function(json) {
        if (json.code === 401) {
          store.dispatch('onSetLoginUser', '')
          router.replace({ path: '/login' })
          reject()
        } else {
          resolve(json)
        }
      }).catch(function(ex) {
        Message.error('由于网络原因请求失败，请刷新页面');
        reject(ex)
      })
    })
  }
}
