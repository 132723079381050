<!--  -->
<template>
  <div>
    <PubHeader title="微信机器人"></PubHeader>
    <QRCode :botId="botId"></QRCode>
  </div>
</template>
<style lang='less' scoped>
</style>
<script>
import PubHeader from '../components/PubHeader.vue'
import QRCode from '../components/WeChatQRCode.vue'
export default {
  data () {
    return {
      botId: ''
    }
  },
  components: {
    PubHeader,
    QRCode
  },
  computed: {

  },
  mounted () {
    this.getBotInfo()
  },
  methods: {
    getBotInfo () {
      this.$httpHelper.post('v1/getAllBot', {}).then(res => {
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            this.botId = res.data[0].id
          }
        }
      })
    }
  }
}
</script>
