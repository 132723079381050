import { render, staticRenderFns } from "./PubHeader.vue?vue&type=template&id=8503dd50&scoped=true"
import script from "./PubHeader.vue?vue&type=script&lang=js"
export * from "./PubHeader.vue?vue&type=script&lang=js"
import style0 from "./PubHeader.vue?vue&type=style&index=0&id=8503dd50&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8503dd50",
  null
  
)

export default component.exports