<!--  -->
<template>
  <div>
    <PubHeader title="消息查看"></PubHeader>
    <div class="msg-box">
      <!-- <div class="contact-box">
        <Contacts></Contacts>
      </div> -->
      <div class="msg-list">
        <div class="tools-box">
          <el-input placeholder="请输入关键字搜索(会话/发送人/发送内容)"
                    v-model="searchText"
                    class="input-with-select">
            <div slot="append">
              <el-button type="primary"
                         size="small"
                         @click="onSearch">搜索</el-button>
              <el-button type="primary"
                         size="small"
                         @click="onReload">刷新消息</el-button>
            </div>
          </el-input>
        </div>
        <div class="table-box">
          <el-table border
                    :height="innerHeight"
                    v-loading="loading"
                    :data="tableData">
            <el-table-column type="index"
                             label="序号"
                             width="50">
            </el-table-column>
            <el-table-column prop="to"
                             label="会话"
                             width="220">
              <template slot-scope="scope">
                <div class="from-info">
                  <div class="avatar">
                    <el-avatar class=""
                               shape="square"
                               :size="30"
                               :src="scope.row.to.avatar"></el-avatar>
                  </div>
                  <div>
                    <span v-if="scope.row.roomid"
                          style="margin-left:10px;">{{scope.row.to.topic}}</span>
                    <span v-else
                          style="margin-left:10px;">{{scope.row.to.name}}</span>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="from"
                             label="发送人"
                             width="180">
              <template slot-scope="scope">
                <div class="from-info">
                  <el-avatar shape="square"
                             :size="30"
                             :src="scope.row.from.avatar"></el-avatar>
                  <span style="margin-left:10px;">{{scope.row.from.name}}</span>
                </div>
              </template>
            </el-table-column>

            <el-table-column prop="text"
                             label="发送内容">
            </el-table-column>
            <el-table-column prop="reply"
                             label="自动回复">

            </el-table-column>
            <el-table-column prop="addtime"
                             width="200"
                             label="发送时间">
              <template slot-scope="scope">
                <div>{{scope.row.addtime}}</div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination">
          <el-pagination :page-size="pageSize"
                         layout="prev, pager, next"
                         :total="totalCount"
                         @current-change="onPageChange">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang='less' scoped>
.msg-box {
  display: flex;
  height: calc(100vh - 105px);
  position: relative;
  .contact-box {
    width: 200px;
    flex: none;
    border-right: 1px solid #f1f1f1;
    overflow-y: auto;
  }
  .msg-list {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    .tools-box {
      height: 60px;
      line-height: 60px;
      padding: 0px 10px;
      box-sizing: border-box;
    }
    .table-box {
      flex: 1;
      overflow-y: auto;
      overflow: hidden;
      position: relative;
    }
    .pagination {
      padding: 10px;
      background-color: #fff;
    }
  }
}

/deep/.from-info {
  display: flex;
  align-items: center;
  .avatar {
    flex: none;
  }
}
</style>
<script>
import PubHeader from '../components/PubHeader.vue'
// import Contacts from '../components/Contacts.vue'
export default {
  data () {
    return {
      searchText: '',
      pageSize: 0,
      loading: false,
      pageIndex: 1,
      tableData: [],
      totalCount: 0
    }
  },
  components: {
    PubHeader,
    // Contacts
  },
  computed: {
    innerHeight () {
      const screenHeight = window.innerHeight - 222;
      console.log(screenHeight); // 输出当前屏幕的高度
      return screenHeight
    }
  },
  mounted () {
    this.getMessages()
  },
  methods: {
    onReload () {
      this.pageIndex = 1
      this.searchText = ''
      this.getMessages()
    },
    onSearch () {
      this.pageIndex = 1
      this.getMessages()
    },
    onPageChange (val) {
      this.pageIndex = val
      this.getMessages()
    },
    getMessages () {
      this.tableData = []
      this.loading = true
      this.$httpHelper.post('v1/getmessages', { page: this.pageIndex, searchText: this.searchText }).then(res => {
        this.loading = false
        if (res.code === 0) {
          this.tableData = res.data
          this.totalCount = res.rows
          this.pageSize = res.pagesize
        }
      })
    }
  }
}
</script>
